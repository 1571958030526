<template>
    <div class="favorite_setting_modal">
        <video id="previewvideo" hidden autoplay playsinline></video>
        <confirm-comp
            id="playStop"
            :text1="'재생중이 음악이 있을 경우,'"
            :text2="'미리듣기를 이용할 수 없습니다.'"
            :text3="'재생중인 음악을 중지해드릴까요?'"
            v-if="isPlayStop"
            @close-modal="onCloseModal"
            @confirm-modal="onConfirmModal"
        />
        <confirm-comp
            id="autoRecomm"
            :text1="'선호자동추천으로 변경하시면 선택하신'"
            :text2="'‘음악‘ 정보가 모두 선택 해제됩니다.'"
            :text3="'선택하신 음악으로 계속 하시겠습니까?'"
            @close-modal="onAutoRecommCloseModal"
            @confirm-modal="onAutoRecommConfirmModal"
            v-if="isAutoRecomm"
        />
        <img class="close_btn" src="/media/images/icon_close_24.svg" @click="$emit('close-modal')" alt="닫기" />
        <header class="favorite_setting_title_wrap">
            <h2 class="favorite_setting_title">선호 음악 선택 (3~10개)</h2>
            <h4 class="favorite_setting_subtitle">길게 눌러 음악을 들어보세요.</h4>
        </header>
        <main class="favorite_setting_body">
            <favorite-loading v-if="isLoading"></favorite-loading>
            <ul v-else class="favorite_music_list">
                <li
                    class="favorite_item"
                    v-for="(item, index) in favoiteList"
                    :key="item.musicId"
                    @touchstart="onTouchStart(item)"
                    @touchend="onTouchEnd(item, $event)"
                    @contextmenu.prevent="
                        () => {
                            return false;
                        }
                    "
                >
                    <figure class="relative">
                        <img
                            class="music_img"
                            :src="item.imgUrl"
                            :alt="item.musicNm"
                            @contextmenu.prevent="
                                () => {
                                    return false;
                                }
                            "
                        />
                        <div v-if="selectPreferMusic.includes(item.musicId)" class="favorite_active">
                            <img class="favorite_hart" src="/media/img/space/hart.png" alt="좋아요" />
                        </div>
                    </figure>
                    <transition name="fade">
                        <div
                            v-if="item.isActive"
                            class="favorite_hover cursor"
                            :class="{ first: (index + 1) % 3 === 1, middle: (index + 1) % 3 === 2, last: (index + 1) % 3 === 0 }"
                        >
                            <div id="rythmWrap" class="rythm_wrap" v-if="item.isPlay" @click.prevent="onMusicPlay(index)">
                                <ul id="rythmItem" class="rythm cursor">
                                    <li id="rythmItem1" class="rythm_item"></li>
                                    <li id="rythmItem2" class="rythm_item second"></li>
                                    <li id="rythmItem3" class="rythm_item last"></li>
                                </ul>
                            </div>
                        </div>
                    </transition>
                    <strong class="farvorite_item_song_title">{{ item.musicNm }}</strong>
                    <span class="farvorite_item_song_artist">{{ item.artist }}</span>
                </li>
            </ul>
        </main>
        <footer class="favorite_setting_footer dp_flex flex_justify_center align_center">
            <button class="autorecommend_btn" @click="onAutoRecommend">
                <img class="auto_recommbtn_img" src="/media/img/space/space_light.png" alt="자동추천" />
                자동 추천받을래요.
            </button>
            <button :disabled="btnDisabled" class="favorite_btn confirm" @click="onClickConfirmBtn">완료</button>
        </footer>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import {
  getPreferMusicList,
  selectSimilarMusicList,
  automaticRecommOfPreferMusic,
  updatePreferMusicList
} from '@/service/api/profileApi';
import Hls from 'hls.js';
import { retryAutomaticRecomm } from '@/utils/Utils';
export default defineComponent({
  name: 'PlayerFavoriteMobile',
  props: {
    curationid: {
      type: String,
      required: false,
      default: ''
    },
    spaceid: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['close-modal', 'complete-favorite'],
  components: {
    'confirm-comp': () => import(`@/components/modal/Space/ConfirmComp.vue`),
    'favorite-loading': () => import(`@/components/space/FavoriteLoading.vue`)
  },
  data () {
    return {
      favoiteList: [],
      isPlayStop: false,
      selectItem: null,
      previewvideo: null,
      config: {
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      },
      selectPreferMusic: [],
      isLoading: false,
      isAutoRecomm: false,
      bottomBtnDisabled: false
    };
  },
  created () {
    this.setPreferMusicList();
  },

  mounted () {
    this.setPreviewVideo();
  },

  methods: {
    setPreviewVideo () {
      this.previewvideo = document.querySelector('#previewvideo');
      if (Hls.isSupported()) {
        this.hls = new Hls(this.config);
      }
      this.previewvideo.addEventListener('ended', this.onVideoEnded);
    },
    onVideoEnded () {
      this.resetPlayer();
    },
    setPreferMusicList () {
      this.isLoading = true;
      getPreferMusicList(this.curationid)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const len = result.length;
            for (let i = 0; i < len; i++) {
              result[i].isActive = false;
              result[i].isPlay = false;
              result[i].imgUrl = `${process.env.VUE_APP_BASE_URL}${result[i].albumImg}`;
              result[i].longTouchToggle = false;
              result[i].touchstarttime = null;
              result[i].isNotRecommendSong = true;
              result[i].isCheckRecommendSong = false;
              this.favoiteList.push(result[i]);
            }
          } else {
            this.favoiteList = [];
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetPlayer (musicId = null) {
      const len = this.favoiteList.length;
      for (let i = 0; i < len; i++) {
        const { musicId: diffMusicId } = this.favoiteList[i];
        if (musicId && diffMusicId === musicId) {
          continue;
        }
        this.favoiteList[i].isPlay = false;
        this.favoiteList[i].isActive = false;
      }
    },
    getMusicPath (path) {
      const musicPath =
        window.location.host.indexOf('localhost') !== -1
          ? `http://13.209.152.125:10084/stream/getMusic?${path}`
          : `stream/getMusic?${path}`;

      return musicPath;
    },
    onAutoRecommCloseModal () {
      this.isAutoRecomm = false;
    },
    onAutoRecommend () {
      if (this.selectPreferMusic.length > 0) {
        this.isAutoRecomm = true;
      } else {
        this.onAutoRecommConfirmModal();
      }
    },
    onAutoRecommConfirmModal () {
      this.isAutoRecomm = false;
      this.selectPreferMusic = [];
      this.checkAutoRecomm = true;
      this.onClickConfirmBtn();
    },
    onCloseModal () {
      this.isPlayStop = false;
    },
    onConfirmModal () {
      this.$VideoPlayer.onPause();
      const { musicId } = this.selectItem;
      // 켜져있는 것 종료
      this.resetPlayer(musicId);
      // 재생 처리
      this.isPlayStop = false;
      const len = this.favoiteList.length;
      for (let i = 0; i < len; i++) {
        const findIdx = this.favoiteList.findIndex(item => item.musicId === musicId);
        if (findIdx > -1) {
          const { musicId } = this.favoiteList[findIdx];
          this.favoiteList[findIdx].isActive = true;
          this.favoiteList[findIdx].isPlay = true;
          const musicPath = this.getMusicPath(musicId);
          this.setMusic(musicPath);

          this.previewvideo.play().catch(() => {
            this.favoiteList[findIdx].isPlay = false;
          });

          break;
        }
      }
    },
    onTouchStart (item) {
      item.touchstarttime = new Date();
      const { musicId } = item;
      if (!item.longTouchToggle) {
        item.longTouchToggle = true;
        const timeout = setTimeout(() => {
          if (item.longTouchToggle) {
            if (this.$store.state.isPlay) {
              this.selectItem = item;
              this.isPlayStop = true;
            } else {
              clearTimeout(timeout);
              this.$VideoPlayer.onPause();
              // 켜져있는 것 종료
              this.resetPlayer(musicId);
              const musicPath = this.getMusicPath(musicId);
              this.setMusic(musicPath);
              item.isPlay = true;
              item.isActive = true;
              item.longTouchToggle = false;

              this.previewvideo.play().catch(() => {
                item.isPlay = false;
              });
            }
          } else {
            item.isPlay = false;
            item.isActive = false;
          }
        }, 1500);
      }
    },
    onTouchEnd (paramItem, $event) {
      if ($event) {
        const { id } = $event.target;
        if (id === 'playBtn' || id === 'rythmWrap' || id.includes('rythmItem')) return;
      }
      const { touchstarttime, isNotRecommendSong, isCheckRecommendSong } = paramItem;
      const curTime = new Date();
      const diff = (curTime.getTime() - touchstarttime.getTime()) / 1000;
      const milseconds = Math.ceil(diff * 10) / 10;
      if (milseconds <= 0.5) {
        paramItem.longTouchToggle = false;
        const { musicId } = paramItem;
        const findIdx = this.selectPreferMusic.findIndex(item => item === musicId);
        if (findIdx > -1) {
          this.selectPreferMusic.splice(findIdx, 1);
        } else {
          this.selectPreferMusic.push(musicId);
          if (isNotRecommendSong && !isCheckRecommendSong) {
            paramItem.isCheckRecommendSong = true;
            selectSimilarMusicList(this.spaceid, musicId, this.favoiteList.map(item => item.musicId).join(',')).then(
              res => {
                const { resultCd, result } = res.data;
                if (resultCd === '0000') {
                  const returnList = this.getFavorite(result);
                  const findIndex = this.favoiteList.findIndex(item => item.musicId === musicId);
                  const firstSlice = this.favoiteList.slice(0, findIndex + 1);
                  const lastSlice = this.favoiteList.slice(findIndex + 1);
                  this.favoiteList = [...firstSlice, ...returnList, ...lastSlice];
                }
              }
            );
          }
        }
      }
    },
    getFavorite (result = []) {
      const newArray = [];
      const len = result.length;
      for (let i = 0; i < len; i++) {
        result[i].isActive = false;
        result[i].isPlay = false;
        result[i].imgUrl = `${process.env.VUE_APP_BASE_URL}${result[i].albumImg}`;
        result[i].longTouchToggle = false;
        result[i].touchstarttime = null;
        result[i].isNotRecommendSong = false;
        result[i].isCheckRecommendSong = false;

        newArray.push(result[i]);
      }

      return newArray;
    },
    onMusicPlay (index) {
      if (this.favoiteList[index]) {
        const { musicId, isPlay } = this.favoiteList[index];
        if (isPlay) {
          this.favoiteList[index].isPlay = false;
          this.favoiteList[index].isActive = false;
          this.favoiteList[index].longTouchToggle = false;
          this.previewvideo.pause();
        } else {
          if (this.$store.state.isPlay) {
            this.selectItem = this.favoiteList[index];
            // 플레이 모달 띄우기
            this.isPlayStop = true;
          } else {
            // 켜져있는 것 종료
            this.resetPlayer(musicId);

            // 새롭게 play
            this.favoiteList[index].isPlay = true;
            const musicPath = this.getMusicPath(musicId);

            this.setMusic(musicPath);

            this.previewvideo.play().catch(() => {
              this.favoiteList[index].isPlay = false;
            });
          }
        }
      }
    },
    setMusic (musicPath) {
      if (Hls.isSupported()) {
        this.hls.loadSource(`${musicPath}`);
        this.hls.attachMedia(this.previewvideo);
      } else {
        this.previewvideo.src = musicPath;
      }
    },
    async onClickConfirmBtn () {
      this.bottomBtnDisabled = true;
      if (this.checkAutoRecomm) {
        try {
          const { data } = await automaticRecommOfPreferMusic(this.curationid);
          const { resultCd } = data;
          if (resultCd !== '0000') return false;
        } catch (error) {
          if (error.response) {
            const { url } = error.response.config;
            if (url === '/api/profile/automaticRecommOfPreferMusic') {
              console.log(error.response);
              retryAutomaticRecomm(
                0,
                () => {
                  this.bottomBtnDisabled = false;
                },
                () => {
                  this.bottomBtnDisabled = false;
                }
              );
            }
          }
          return false;
        } finally {
          this.bottomBtnDisabled = false;
        }
      } else {
        try {
          const { data } = await updatePreferMusicList(this.spaceid, this.selectPreferMusic, this.curationid);
          const { resultCd } = data;
          if (resultCd !== '0000') return false;
        } catch (error) {
          //
          return false;
        } finally {
          this.bottomBtnDisabled = false;
        }
      }

      this.$emit('complete-favorite');
    }
  },
  computed: {
    btnDisabled () {
      if (this.bottomBtnDisabled) return true;

      if (this.checkAutoRecomm) {
        return false;
      } else {
        if (this.selectPreferMusic.length >= 3 && this.selectPreferMusic.length <= 11) {
          return false;
        }
        return true;
      }
    }
  }
});
</script>
<style scoped src="@/assets/css/player/playerfavoritemobile.css"></style>
